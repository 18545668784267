import React from 'react'
import './GallerieEvents.css';

const GallerieEvents = () => {
    return (
        <div>
            <div class="container">

                <div class="gallery-wrap">
                    
                    <div class="item item-2"></div>
                    <div class="item item-3"></div>
                    <div class="item item-4"></div>
                    
                    
                </div>
            </div>

        </div>
    )
}

export default GallerieEvents